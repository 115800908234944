var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"web-details"},[_c('section',{staticClass:"web-details__meta meta"},[_c('div',{staticClass:"web-details__header"},[_c('div',{staticClass:"web-details__site-id animate-hide"},[_c('p',{staticClass:"id-tag"},[_vm._v("0"+_vm._s(_vm.site.id))])]),_c('router-link',{staticClass:"btn go-back clickable animate-hide",attrs:{"to":{name: 'Welcome'},"tag":"button"}},[_vm._v(" ↢ ")])],1),_c('div',{staticClass:"web-details__details"},[_c('h2',{staticClass:"meta__title animate-hide"},[_vm._v(_vm._s(_vm.site.title))]),_c('p',{staticClass:"meta__description animate-hide"},[_vm._v(_vm._s(_vm.site.desc))]),_c('p',{staticClass:"meta__tags animate-hide"},[_vm._v(_vm._s(_vm.site.tags))]),_c('a',{staticClass:"meta__link clickable animate-hide",attrs:{"href":_vm.site.href,"target":"_blank"}},[_c('button',{staticClass:"btn btn-view-site"},[_vm._v("WEBSITE")])])]),_c('div',{staticClass:"web-details__navigation animate-hide"},[_c('router-link',{staticClass:"meta__prev clickable",class:{disabled: !_vm.prevSite},attrs:{"to":{
                    name: 'web-details', 
                    params: {
                        link: _vm.prevSite.link,
                        site: _vm.prevSite
                    }
                    }}},[_c('button',{staticClass:"btn btn-previous"},[_vm._v("PREV")])]),_c('span',[_vm._v("|")]),_c('router-link',{staticClass:"meta__next clickable",class:{disabled: !_vm.nextSite},attrs:{"to":{
                    name: 'web-details', 
                    params: {
                        link: _vm.nextSite.link,
                        site: _vm.nextSite

                    }
                    }}},[_c('button',{staticClass:"btn btn-next"},[_vm._v("NEXT")])])],1)]),_c('aside',{staticClass:"web-details__preview"},[_c('div',{staticClass:"loader"},[_c('Loader')],1),_c('img',_vm._b({},'img',_vm.imgAttr,false))])])}
var staticRenderFns = []

export { render, staticRenderFns }